/* * @Author: 王浩 * @Date: 2021-07-29 09:23:28 * @Last Modified by: 王浩 * @Last Modified time: 2021-07-29 09:23:28 */

<template>
  <div class="sz-fixed-footer-warpper" slot="footer">
    <div class="sz-fixed-footer">
      <div class="sz-fixed-footer-item" :class="{ active: name === 'lhHome' }" @click.stop="goUrl('lhHome')">
        <div class="sz-fixed-footer-item__icon">
          <sz-icon class="ft22" name="shouye-yixuan" v-if="name === 'lhHome'"></sz-icon>
          <sz-icon class="ft22" name="shouye-weixuan" v-else></sz-icon>
        </div>
        <div class="sz-fixed-footer-item__name">精选商品</div>
      </div>
      <div class="sz-fixed-footer-item" :class="{ active: name === 'order' }" @click.stop="goUrl('order')">
        <div class="sz-fixed-footer-item__icon">
          <sz-icon class="ft22" name="wode-yixuan" v-if="name === 'order'"></sz-icon>
          <sz-icon class="ft22" name="wode-weixuan" v-else></sz-icon>
        </div>
        <div class="sz-fixed-footer-item__name">我的订单</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {}
  },
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  created() {},
  methods: {
    goUrl(type) {
      switch (type) {
        case 'lhHome':
          this.$router.push({ name: 'lhHome', query: this.$route.query })
          break
        case 'order':
          this.$router.push({ name: 'lhOrder', query: this.$route.query })
          break
      }
    }
  },
  computed: {},
  components: {}
}
</script>

<style scoped lang="scss">
.sz-fixed-footer-warpper {
  height: 120px;
}
.sz-fixed-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  height: 120px;
  background: #fff;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08);
  &-item {
    flex: 1;
    box-sizing: border-box;
    height: 120px;
    padding-top: 18px;
    text-align: center;
    color: $sz-color-gray;
    &.active {
      color: #0D7CFF;
    }
    &__icon {
      display: inline-block;
      width: pxToRem(22);
      height: pxToRem(22);
    }
    &__name {
      font-size: pxToRem(10);
    }
  }
}
.dotted {
  position: relative;
  &::after {
    position: absolute;
    top: 0rem;
    right: 0rem;
    width: 1rem;
    height: 1rem;
    background: #0D7CFF;
    border-radius: 50%;
    transform: scale(0.1);
    transform-origin: 100% 0;
    content: '';
  }
}
</style>
