<template>
  <div :style="getPageStyle" class="sz-pagea" :class="{ 'in-safari': isInSafari && bottomOffset }">
    <div v-if="title" class="sz-pagea-header">
      <div v-if="hasBack" class="sz-pagea-header-left" @click="actionBack">
        <sz-icon class="ft16" name="back"></sz-icon>
      </div>
      <div class="sz-pagea-header-body">{{ title }}</div>
      <div v-if="hasMenu" class="sz-pagea-header-right" @click="actionMenu">
        <sz-icon class="ft16" name="menu"></sz-icon>
      </div>
    </div>
    <div :class="{ 'has-padding': isInSafari && bottomOffset }" ref="pageBody" class="sz-pagea-body" @scroll="actionScroll">
      <slot></slot>
    </div>

    <slot v-if="$slots.footer" name="footer"></slot>
    <div v-else-if="$slots.other || $slots.btn" class="sz-pagea-footer">
      <div class="sz-pagea-footer__container">
        <div v-if="$slots.other" class="sz-pagea-footer-other">
          <slot name="other"></slot>
        </div>
        <div :class="{ full: !$slots.other }" v-if="$slots.btn" class="sz-pagea-footer-btn">
          <slot name="btn"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { throttle } from '@/module/tools'

@Component
export default class SzPage extends Vue {
  /* 外部传入属性 */
  /* 外部传入属性 */
  @Prop({ default: '' }) private title!: string // 标题
  @Prop({ default: false }) private hasBack!: boolean // 是否有返回按钮
  @Prop({ default: false }) private hasMenu!: boolean // 是否有菜单按钮
  @Prop({ default: 0 }) private offset!: number // // 滚动条与底部距离小于 offset 时触发 on-scroll-end 事件（滚动到底）
  @Prop({ default: true }) private bottomOffset!: boolean // // 滚动条与底部距离小于 offset 时触发 on-scroll-end 事件（滚动到底）

  /* 组件内部数据 */
  ua = '' // 当前环境的user-agent
  hasPadding = false // 是否加padding
  isToEnd = false // 是否到底 如果到底后，一次到底只触发一次

  /* 一.生命周期函数 */
  created() {
    this.ua = navigator.userAgent
  }

  mounted() {
    this.checkStatus()
  }

  /* 二.监控函数 watch computed */
  @Watch('ua')
  onMsgChanged(val: string, oldVal: string): void {
    console.log('[ua 发生变化]', val, oldVal)
  }

  // 如果是在 Safari， 需要底部加一个 Padding，防止底部被遮挡
  get isInSafari() {
    const ua = this.ua
    if (ua.indexOf('iPhone') > -1 && ua.indexOf('Safari') > -1) {
      return true
    } else {
      return false
    }
  }

  get getPageStyle() {
    const oStyle: any = {}

    return oStyle
  }

  /* 三.内部功能函数 */
  /* ----------------------事件调用函数------------------------ */
  actionScroll = throttle((ev: any) => {
    this.$emit('on-scroll', ev)

    const element = ev.srcElement

    const scrollHeight = element.scrollHeight // 滚动条总长度
    const offsetHeight = element.offsetHeight // 容器高度
    const scrollTop = element.scrollTop // 已滚动距离

    // Math.ceil 用于兼容 JS 计算误差
    if (Math.ceil(offsetHeight + scrollTop) >= scrollHeight - this.offset) {
      // 这里的 isToEnd 是当 offset 存在时，防止触底后 on-scroll-end 多次触发
      if (!this.isToEnd) {
        this.isToEnd = true
        this.$emit('on-scroll-end')
      }
    } else if (this.isToEnd) {
      this.isToEnd = false
    }
  }, 50)
  // 点击返回按钮（左上角图标）
  actionBack() {
    this.$emit('on-back')
    if (this.$router) {
      this.$router.back()
    } else {
      history.back()
    }
  }
  // 点击菜单按钮（右上角图标）
  actionMenu() {
    this.$emit('on-menu')
  }
  // 校验状态，这里主要看是初始化后是否滚动到底部
  checkStatus() {
    const oPageBody: any = this.$refs.pageBody
    if (oPageBody.offsetHeight >= oPageBody.scrollHeight - this.offset) {
      // 说明尚未超出一屏，不包含滚动条，直接返回 on-scroll-end
      this.$emit('on-scroll-end')
    }
    console.log('[pageBody]', oPageBody.scrollHeight)
  }
  // 滚动到某个位置， 为数值是表示滚动到具体位置， 为字符串时表示滚动到预置位置 bottom 滚动到底部
  scrollTo(offset: number | string) {
    const oPageBody: any = this.$refs.pageBody
    switch (offset) {
      case 'bottom':
        offset = oPageBody.scrollHeight + oPageBody.offsetHeight
        break
    }

    // 滚动到某个位置
    oPageBody.scrollTop = offset
  }
  /* ----------------------内部功能函数------------------------ */
  /* ----------------------服务请求函数------------------------ */
}
</script>

<style scoped lang="scss">
// 页面布局
.sz-pagea {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  &-header {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    height: 100px;
    background: #fff;

    &-left {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
    }

    &-right {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
    }

    &-body {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
      margin-left: 1rem;
      font-size: 0.32rem;
    }
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 1px;
      background: $sz-color-border-lightgray;
      transform: scaleY(0.5);
      content: ' ';
    }
  }

  &-body {
    position: relative;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    // 主要应对ios 内置Safari浏览器底部被遮挡问题
    &.has-padding {
      &::after {
        display: block;
        width: 100%;
        height: 50px;
        background: transparent;
        content: ' ';
      }
    }
  }

  &-footer {
    flex: 0 0 auto;
    width: 100%;
    height: 50px;
    // 主要应对ios 内置Safari浏览器底部被遮挡问题
    &__container {
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      width: 100%;
      min-height: 50px;
      background: $sz-color-white;
      box-shadow: 0px -1px rem(24) 0px rgba(108, 118, 138, 0.1);
    }

    &-other {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      &.center {
        justify-content: center;
      }
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 130px;
      color: $sz-color-white;
      background: $sz-color-blue;
      & > * {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
      &.full {
        width: 100%;
      }
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: $sz-color-border-lightgray;
      content: ' ';
    }
  }
}
</style>
