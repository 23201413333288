/*
 * @Author: 王浩
 * @Date: 2021-07-06 17:05:29
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-09-08 17:01:40
 * 全局属性定义专用插件
 */
import Vue, { PluginObject } from 'vue'
import { setWXSDK, rem2px, getCookie, isWechat, loadCss } from '@/module/tools'
import xss from 'xss'
const GlobalVarlugin: PluginObject<Vue> = {
  install(Vue) {
    // 加载 Vant 样式
    loadCss('https://static.shanzhen.com/js/vant@2.12/lib/index.min.css')

    // 注册全局指令
    // Vue.filter('img', (val: string) => {
    //   return process.env.VUE_APP_STATIC_URL + val
    // })

    Vue.filter('price', val => {
      if (typeof val !== 'number') return ''
      return `${(val / 1000).toFixed(2)}`
    })

    // http://asgard.shanzhen.com/
    Vue.filter('asgard', val => {
      return process.env.VUE_APP_ASGARD_URL + val
    })
    // img.shanzhen.com
    Vue.filter('oldImg', val => {
      return process.env.VUE_APP_IMG_URL + val
    })
    // static.shanzhen.com
    Vue.filter('img', val => {
      if(location.origin.includes('mdev-a')) {
        return 'https://staticdev-a.shanzhen.com/' + val
      }
      if(location.origin.includes('mtest')) {
        return 'https://statictest.shanzhen.com/' + val
      }
      return process.env.VUE_APP_STATIC_URL + val
    })
    // https://att.shanzhen.com/
    Vue.filter('att', val => {
      return process.env.VUE_APP_ATT_URL + val
    })

    // 添加微信ready方法
    Vue.prototype.$wxReady = setWXSDK
    Vue.prototype.$toast = vant.Toast
    Vue.prototype.$dialog = vant.Dialog
    Vue.prototype.$rem2px = rem2px
    Vue.prototype.$getCookie = getCookie
    Vue.prototype.$isWechat = isWechat()
    Vue.prototype.$xss = xss
    Vue.prototype.$scrollBy = window.scrollBy
    Vue.prototype.$toLogin = (backUrl = '') => {
      let bcmQuery = ''
      try {
        if (Vue.prototype.$store.state.fromCompany === 'bcm') {
          bcmQuery = '&fromCompany=bcm'
        }
      } catch (err) {
        console.log(err)
      }
      if (process.env.NODE_ENV === 'development') {
        location.href =
          process.env.VUE_APP_LOGIN_HOST + `/wap/login/login?_szlogout=1${bcmQuery}&backUrl=${encodeURIComponent(backUrl || location.href)}`
      } else {
        location.href = `/wap/login/login?_szlogout=1${bcmQuery}&backUrl=${encodeURIComponent(backUrl || location.href)}`
      }
    }
  }
}

export default GlobalVarlugin
