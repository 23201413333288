const LhSso = () => import(/* webpackChunkName: "lhSso" */ '@/views/home/lhSso.vue')
const LhHome = () => import(/* webpackChunkName: "lhHome" */ '@/views/home/lhHome.vue')
const LhOrder = () => import(/* webpackChunkName: "lhOrder" */ '@/views/user/lhOrderList.vue')
const lhInstitute = () => import(/* webpackChunkName: "lhInstitute" */ '@/views/goods/lhGoods/institute.vue')
const LhVaccine = () => import(/* webpackChunkName: "lhVaccine" */ '@/views/goods/lhGoods/vaccine.vue')
const basePath = '/lh'

export default [
  {
    path: basePath + '/sso',
    name: 'lhSso',
    component: LhSso,
    meta: {
      title: '登录'
    }
  },
  {
    path: basePath + '/home',
    name: 'lhHome',
    component: LhHome,
    meta: {
      title: '健康管理平台'
    }
  },
  {
    path: basePath + '/vaccine',
    name: 'lhVaccine',
    component: LhVaccine,
    meta: {
      title: '疫苗'
    }
  },
  {
    path: basePath + '/order',
    name: 'lhOrder',
    component: LhOrder,
    meta: {
      title: '订单列表'
    }
  },
  {
    path: basePath + '/institute',
    name: 'lhInstitute',
    component: lhInstitute,
    meta: {
      title: '订单列表'
    }
  }
]
