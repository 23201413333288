<template>
  <div>
    <szPage class="home" @on-scroll="actionScroll">
      <div class="home-body">
        <!-- 顶部菜单 两个主入口 -->
        <div class="home-menu" v-if="mainAdDtoList.length > 0">
          <div
            class="home-menu-item"
            @click="
              trackClick({ description: mainAdDtoList[0].title }, () => {
                actionRouteTo('goodsRecommend')
              })
            "
          >
            <div class="home-menu-item-title">
              <span>{{ mainAdDtoList[0].title }}</span>
              <sz-icon class="home-menu-item-title-icon ft18 ml4" name="xiangqing2"></sz-icon>
            </div>
            <div class="home-menu-item-desc">{{ mainAdDtoList[0].desc }}</div>
          </div>
          <div
            class="home-menu-item"
            @click="
              trackClick({ description: mainAdDtoList[1].title }, () => {
                actionRouteTo('userOrderList')
              })
            "
          >
            <div class="home-menu-item-title">
              <span>{{ mainAdDtoList[1].title }}</span>
              <sz-icon class="home-menu-item-title-icon ft18 ml4" name="xiangqing2"></sz-icon>
            </div>
            <div class="home-menu-item-desc">{{ mainAdDtoList[1].desc }}</div>
          </div>
        </div>

        <!-- 中部导航栏 -->
        <div class="home-nav">
          <!-- <div
            class="home-nav-item"
            @click="
              trackClick({ description: '预约体检' }, () => {
                actionCheckLoginJumpTo('/wap2/user/order_list?pageSource=userIndex')
              })
            "
          >
            <img class="home-nav-item-icon" src="@/assets/images/home/nav1.png" />
            <div class="home-nav-item-title">预约体检</div>
          </div> -->
          <div
            class="home-nav-item"
            @click="
              trackClick({ description: '查看报告' }, () => {
                actionCheckLoginJumpTo('/wap2/report_v2/compare')
              })
            "
          >
            <img class="home-nav-item-icon" src="@/assets/images/home/nav2.png" />
            <div class="home-nav-item-title">查看报告</div>
          </div>
          <div
            class="home-nav-item"
            @click="
              trackClick({ description: '咨询套餐' }, () => {
                actionJumpTo('/cooperation/little-helper?activityCode=ACT670277396')
              })
            "
          >
            <img class="home-nav-item-icon" src="@/assets/images/home/nav5.png" />
            <div class="home-nav-item-title">咨询套餐</div>
          </div>
          <div
            class="home-nav-item"
            @click="
              trackClick({ description: '善太医体检金刚位' }, () => {
                actionJumpTo('/custom/recommend/index')
              })
            "
          >
            <div class="home-nav-item-icon styicon">
              <img width="100%" src="@/assets/images/home/nav4.png" />
            </div>
            <div class="home-nav-item-title">善太医体检</div>
          </div>
          <div
            class="home-nav-item"
            @click="
              trackClick({ description: '体检百科' }, () => {
                actionJumpTo('/data/wiki?pageSource=wap')
              })
            "
          >
            <img class="home-nav-item-icon" src="@/assets/images/home/nav3.png" />
            <div class="home-nav-item-title">体检百科</div>
          </div>
        </div>

        <!-- 订单提示 用户登录状态下 -->
        <div class="home-orderstatus" v-if="isLogin && orderData">
          <div class="home-orderstatus-title">{{ getOrderStatus.title }}</div>
          <div class="home-orderstatus-btn" @click="actionClickOrderBtn(getOrderStatus.link)">
            {{ getOrderStatus.btnText }}
          </div>
        </div>
        <!-- 轮播图 广告位 -->
        <div class="home-banner" v-if="swipeList.length > 0">
          <van-swipe class="my-swipe" @change="actionSwipeChange" :autoplay="3000">
            <van-swipe-item v-if="isLogin && isUserFilial === 1" class="home-banner-content">
              <img width="100%" @click="actionToOldCustomerActivity" :src="require('@/assets/images/home/newcomer-red-envelope.png')" />
            </van-swipe-item>
            <van-swipe-item v-for="(item, index) in swipeList" :key="index" class="home-banner-content">
              <img
                width="100%"
                @click="
                  trackClick({ description: `点击运营位${index + 1}` }, () => {
                    actionJumpTo(item.link)
                  })
                "
                :src="item.banner | img"
              />
            </van-swipe-item>

            <template #indicator>
              <div class="home-banner-pagination">
                <div
                  class="home-banner-pagination-item"
                  v-for="(item, index) in swipeList"
                  :key="index"
                  :class="{ active: activeSwipeNum === index }"
                ></div>
                <div
                  class="home-banner-pagination-item"
                  v-if="isLogin && isUserFilial === 1"
                  :class="{ active: activeSwipeNum === swipeList.length }"
                ></div>
              </div>
            </template>
          </van-swipe>
        </div>

        <!-- 商品货架 -->
        <div class="home-goods">
          <div class="home-goods-title">精选体检</div>
          <GoodsList
            ref="goodsList"
            :theme-colors="{
              tabColor: '#0CC393', //  Tab 栏颜色
              tabLineColor: '#08C393', // Tab 栏下划线颜色
              priceColor: '#FF4200'
            }"
            :change-title="false"
            :page-style="getGoodsListStyle"
            :price-prefix="isVip ? '会员价' : '券后'"
            goods-btn-title="查看套餐"
            activityCode="ACT091543228"
            @on-goods-detail="actionToGoodsDetail"
            @change-tab="actionChangeTab"
            @on-complate="actionScroll"
          ></GoodsList>
        </div>

        <!-- 底部善太医倒流 -->
        <div class="mt10" ref="styBanner" @click="actionToStyRecommend">
          <img width="100%" src="@/assets/images/home/sty_banner.png" />
        </div>

        <!-- 底部广告 -->
        <div class="home-hospitalinfo">
          <img width="100%" src="@/assets/images/home/hospital_info.png" />
        </div>
      </div>

      <szFooter slot="footer" name="home"></szFooter>
    </szPage>
    <!-- 弹窗广告 -->
    <pop-up-ad :data="getPopUpAdData" show-page="HOME_PAGE" v-if="getPopUpAdData && $isWechat"></pop-up-ad>

    <div
      class="home-red-envelope"
      v-if="!isLogin"
      @click="
        trackClick({ description: '点击去登录' }, () => {
          actionCheckLoginJumpTo(`https://m.shanzhen.com/wap2/home?isNoviceLogin=1`)
        })
      "
    >
      <img class="red-envelope" src="@/assets/images/home/red-envelope.png" alt="" />
    </div>
  </div>
</template>

<script lang="ts">
import { getCookie } from '@/module/tools'
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
type OrderData = null | {
  orderStatus: number
  payStatus: number
  orderCode: string
}

interface CardView {
  [key: string]: boolean
}

@Component({
  components: {
    GoodsList: () => import('@/components/goodsList'), // 商品列表 商品货架
    popUpAd: () => import('@/components/popUpAd.vue') // 弹窗广告
  }
})
export default class Home extends Vue {
  @State('isVip') private isVip!: boolean // 是否为会员
  activeSwipeNum = 0 // 当前选中的轮播图
  swipeList: {
    banner: string
    link: string
    showPage: null
  }[] = [] // 轮播图列表
  mainAdDtoList: any[] = [] // 首页顶部菜单数据
  popUpAdList: any = [] // 弹窗广告数据
  isLogin = false // 用户是否登录
  isUserFilial = 2 //是否为新用户 1为新用户2为老用户
  maskMobile = '' // 用户手机号
  orderData: OrderData = null // 订单数据，用来判断用户是否有待处理订单
  cardViewMap: CardView = {}

  /* 一.生命周期函数 */
  created(): void {
    // 获取货架信息
    this.ajaxHomeShow()
    this.isLogin = getCookie('islogin') === '1'
    this.maskMobile = getCookie('maskMobile') || ''

    if (this.maskMobile) {
      this.ajaxQueryPendingOrderList()
      this.ajaxIsUserFilialPackageGuide()
    }
  }
  /* 二.监控函数 */
  // 获取商品列表样式
  get getGoodsListStyle(): any {
    return {
      // goodsBtn: {
      //   background: "#08C393",
      // },
    }
  }

  get getPopUpAdData(): any {
    return this.popUpAdList.find(item => item.showPage === 'HOME_PAGE')
  }

  // 获取订单状态相关数据
  get getOrderStatus(): any {
    const data = {
      title: '',
      btnText: '',
      link: ''
    }

    if (!this.orderData) return data

    const { orderStatus, payStatus, orderCode } = this.orderData
    if (payStatus === 1) {
      data.title = '你有一个体检订单待支付'
      data.btnText = '立即支付'
      data.link = `/wap2/user/tj_detail?orderCode=${orderCode}`
    } else if (payStatus === 2 && orderStatus === 1) {
      data.title = '你有一个体检订单待预约'
      data.btnText = '立即预约'
      data.link = `/wap2/user/tj_detail?orderCode=${orderCode}`
    } else if (payStatus === 2 && orderStatus === 11) {
      data.title = '你有一个体检报告待查看'
      data.btnText = '查看报告'
      data.link = '/wap2/report_v2/compare'
    }

    return data
  }

  /* 三.内部功能函数 */
  /* ----------------------事件调用函数------------------------ */
  // 跳转到善太医推荐页
  actionToStyRecommend() {
    this.trackClick({ description: '善诊底部善太医卡片' }, () => {
      this.actionJumpTo('/custom/recommend/index')
    })
  }
  actionClickOrderBtn(link: string) {
    this.ajaxPendingOrderCliked().finally(() => {
      this.ajaxQueryPendingOrderList()
      this.actionJumpTo(link)
    })
  }

  // 轮播图切换
  actionSwipeChange(currentIndex: number) {
    this.activeSwipeNum = currentIndex
  }

  // 跳转到指定路由
  actionRouteTo(name: string) {
    this.$router.push({ name })
  }

  // 跳转到指定 URL
  actionJumpTo(link: string) {
    location.href = link
  }

  // 点击新人专区banner
  actionToOldCustomerActivity() {
    location.href = `https://m.shanzhen.com/cooperation/welfare23?comId=c7d727e09afd45318a814d5bb3d26882&activityCode=ACT098107697`
  }

  // 校验登录
  actionCheckLoginJumpTo(link: string) {
    // 如果用户登录了则继续，否则去登录
    if (this.isLogin && this.maskMobile) {
      location.href = link
    } else {
      location.href = `/wap/login/login?backUrl=${encodeURIComponent(link)}`
    }
  }

  // 跳转到商品详情
  actionToGoodsDetail(data: any) {
    sessionStorage.setItem('currentGoodsCode', data.goodsCode)
    sessionStorage.setItem('currentGoodsName', data.goodsName)

    this.trackClick(
      {
        description: '套餐卡片',
        item_code: data.goodsCode,
        item_name: data.goodsCode
      },
      () => {
        this.$router.push({
          name: 'goodsDetail',
          query: {
            goodsCode: data.goodsCode,
            activityCode: data.activityCode,
            pageSource: '主站首页'
          }
        })
      }
    )
  }

  // 商品列表 Tab 切换
  actionChangeTab(tabListName: string) {
    this.trackClick({ description: tabListName })

    // 调用该方法是为了触发埋点
    this.actionScroll()
  }

  // 监听页面滚动
  actionScroll() {
    const oGoodsList: any = this.$refs.goodsList
    if (!oGoodsList) return
    const domList: Array<HTMLElement> = oGoodsList.getGoodsEl()
    Array.from(domList).forEach((el: HTMLElement) => {
      const { goodsCode } = el.dataset
      const isShow = this.calcIsShow(el)
      if (goodsCode) {
        if (this.cardViewMap[goodsCode] === isShow) return
        this.cardViewMap[goodsCode] = isShow
      }
    })

    // 如果已经调用过了，则不展示
    if (this.cardViewMap.isShowStyBanner) return
    const oStyBanner = this.$refs.styBanner as HTMLElement
    const isShowStyBanner = this.calcIsShow(oStyBanner)
    this.cardViewMap.isShowStyBanner = isShowStyBanner
  }

  // 计算元素是否在可视区域
  calcIsShow(oDom: any, offset = 0) {
    const box = oDom.getBoundingClientRect(),
      top = box.top >= 0,
      left = box.left >= 0,
      bottom = box.bottom <= (window.innerHeight || document.documentElement.clientHeight) + offset,
      right = box.right <= (window.innerWidth || document.documentElement.clientWidth) + offset

    return top && left && bottom && right
  }

  /* ----------------------内部功能函数------------------------ */
  // 点击事件埋点
  trackClick({ description = '', item_code = '', item_name = '' }, cb?: () => void) {
    console.error(description, item_code, item_name)
    if (cb) {
      cb()
    }
  }

  /* ----------------------服务请求函数------------------------ */
  // 首页轮播图接口
  ajaxHomeShow() {
    this.$api.get('/is/wap/home/show', {}, { cache: true }).then(res => {
      if (res.SZ_HEAD.RESP_CODE === 'S0000') {
        this.swipeList = res.SZ_BODY.HOME_PAGE_MSG.adItemDtoList || []
        this.mainAdDtoList = res.SZ_BODY.HOME_PAGE_MSG.mainAdDtoList || [{}, {}]
        this.popUpAdList = res.SZ_BODY.HOME_PAGE_MSG.popUpAdList || []
      } else {
        this.$toast(res.SZ_HEAD.RESP_MSG)
      }
    })
  }

  // 查询用户的待处理订单
  ajaxQueryPendingOrderList() {
    return this.$api.slget('examine_get_pendingOrderList').then(res => {
      if (res.SZ_HEAD.RESP_CODE === 'S0000') {
        this.orderData = res.SZ_BODY.remindOrderInfoList[0]
      } else {
        this.$toast(res.SZ_HEAD.RESP_MSG)
      }
    })
  }
  // 查询是否是老用户
  ajaxIsUserFilialPackageGuide() {
    this.$api.slpost('isUserFilialPackageGuide').then(res => {
      if (res.SZ_HEAD.RESP_CODE === 'S0000') {
        this.isUserFilial = res.SZ_BODY.userStatus
        // 是否是新人登录,只用一次用完就删为1则是新用户
        const { isNoviceLogin = '0' } = this.$route.query
        if (isNoviceLogin !== '0' && this.isUserFilial === 1) {
          location.href = 'https://m.shanzhen.com/cooperation/welfare23?comId=c7d727e09afd45318a814d5bb3d26882&activityCode=ACT098107697'
          this.$route.query = {}
        }
      }
    })
  }

  // 用户点击订单
  ajaxPendingOrderCliked() {
    const formData = this.orderData || {}
    return this.$api.slpost('examine_pendingOrderCliked', formData)
  }
}
</script>

<style lang="scss" scoped>
.home {
  &-body {
    background: #fff url('~@/assets/images/home/bg.png') top center no-repeat;
    background-size: 100% auto;
    padding: 0 $sz-padding;
    overflow: hidden;
  }

  // 顶部菜单栏
  &-menu {
    margin-top: 130px;
    display: flex;
    justify-content: space-between;

    &-item {
      padding-left: $sz-padding;
      padding-top: 30px;
      width: 333px;
      height: 180px;
      border-radius: 24px;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: cover;
      color: #ffffff;

      &-title {
        display: flex;
        align-items: center;
        font-size: 44px;
        font-weight: 700;
        line-height: 60px;

        &-icon {
          flex: 0 0 auto;
        }
      }

      &-desc {
        margin-top: 2px;
        font-size: 24px;
        line-height: 32px;
      }
    }

    &-item:nth-last-of-type(1) {
      background-image: url('~@/assets/images/home/goods_list.png');
    }

    &-item:nth-last-of-type(2) {
      background-image: url('~@/assets/images/home/doctor.png');
    }
  }

  // 导航栏
  &-nav {
    margin: 50px 0;
    display: flex;

    &-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-icon {
        max-width: 100px;
        font-size: 0;

        &.styicon {
          position: relative;

          &::after {
            position: absolute;
            top: 0;
            right: -72px;
            transform: translateY(-50%);
            content: '精选上新';
            width: 104px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background: #ffad0a;
            border: 1px solid #ffffff;
            border-radius: 18px 18px 18px 0;
            font-size: 20px;
            white-space: nowrap;
          }
        }
      }

      &-title {
        margin-top: 16px;
        color: #7a7a7a;
        font-size: 24px;
      }
    }
  }

  // 订单状态
  &-orderstatus {
    height: 96px;
    background: #f7f9fd;
    border-radius: 32px;
    padding: 18px $sz-padding;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      font-weight: 700;
      flex: 1;
    }

    &-btn {
      flex: 0 0 auto;
      width: 144px;
      height: 60px;
      background: #0cc393;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #ffffff;
    }
  }

  // 轮播图 广告位
  &-banner {
    margin-top: 28px;

    &-content {
      width: 100%;
      height: 143px;
    }

    &-pagination {
      margin-top: 10px;
      display: flex;
      justify-content: center;

      &-item {
        transform: scale(0.5);
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background: #d8d8d8;
        transition: all 0.2s linear;

        &.active {
          width: 32px;
          background: #0cc192;
          border-radius: 8px;
        }
      }
    }
  }

  // 货架 商品列表相关
  &-goods {
    margin: 0 -32px 0;

    &-title {
      margin: 50px $sz-padding 16px;
      font-size: 40px;
      font-weight: 700;
    }
  }

  // 底部医院信息
  &-hospitalinfo {
    margin: 32 -32px 0;
  }

  // 首页红包栏
  &-red-envelope {
    position: fixed;
    bottom: 120px;
    left: 0px;

    & > .red-envelope {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
