<!------------------------------------------- template ------------------------------------------->
<template>
  <van-popup get-container="body" position="bottom" v-model="value" @closed="actionClosed" class="region">
    <div class="region-header">
      <span>选择城市</span>
      <div class="region-header-close" @click="value = false">
        <sz-icon class="ft16" name="close"></sz-icon>
      </div>
    </div>
    <div class="region-body" ref="regionBody">
      <!-- 热门城市 -->
      <section class="region-section" v-if="hotCityList.length &gt; 0">
        <div class="region-section-title">热门城市</div>
        <div class="region-section-body region-hotcity">
          <div @click="actionChooseHotCity(item)" class="region-tag" v-for="(item, index) in hotCityList" :key="index">
            {{ item }}
          </div>
        </div>
      </section>
      <!-- 选择省份地区 -->
      <section class="region-section">
        <div class="region-section-title">选择省份/地区</div>
        <div class="region-section-body">
          <div class="region-list" :key="item.title" v-for="item in aCityList">
            <div class="region-list-item" @click="actionChooseHotCity(city)" :key="index" v-for="(city, index) in item.list">
              <div class="region-list-item-prefix">
                <span v-if="index === 0">{{ item.title }}</span>
              </div>
              <div class="region-list-item-body">{{ city }}</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </van-popup>
</template>

<!-------------------------------------------- script -------------------------------------------->
<script lang="ts">
import { Component, Vue,  Prop } from 'vue-property-decorator'
// 定义类型
interface CityData {
    city: string;
    cityInitials: string;
}

interface GroupedData {
    title: string;
    list: string[];
}

@Component({
  model: {
    prop: 'value',
    event: 'change'
  },
  components: {
    // About: () => import("../About.vue"),
  }
})
export default class Home extends Vue {
  @Prop() private value!: boolean
  @Prop({ default: [] }) private cityList!: Array<any>
  get aCityList() {
    return this.groupAndSortByInitials(this.cityList)
  }
  get hotCityList() {
    return this.cityList.filter(item => item.isHot).map(item => item.city)
  }

    // 弹窗关闭
  actionClosed() {
    this.$emit('change', false)
  }

  groupAndSortByInitials(data: CityData[]): GroupedData[] {
    // 分组操作
    const grouped: Record<string, GroupedData> = data.reduce((acc, item) => {
        const key = item.cityInitials;
        if (!acc[key]) {
            acc[key] = { title: key, list: [] };
        }
        acc[key].list.push(item.city);
        return acc;
    }, {} as Record<string, GroupedData>);

    // 转换为数组并按 title 排序
    return Object.values(grouped).sort((a, b) => a.title.localeCompare(b.title));
  }

  actionChooseHotCity(city) {
    this.$emit('on-complete', city)
  }
}
</script>

<!-------------------------------------------- style -------------------------------------------->
<style scoped lang="scss">
.region {
  height: 85vh;
  border-radius: 32px 32px 0 0;
  display: flex;
  flex-direction: column;
  &-header {
    width: 100%;
    position: relative;
    font-size: 36px;
    line-height: 100px;
    text-align: center;
    &-close {
      color: #c8c9cc;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
    }
  }
  .flex-box {
    display: flex;
  }
  // 弹窗大小
  &-body {
    position: relative;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    // 底部关闭按钮
    &-footer {
      width: 100%;
      height: 96px;
      &-container {
        width: 100%;
        height: 96px;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: center;
        z-index: 1;
        bottom: 0;
        left: 0;
        background: #fff;
        &-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 192px;
          height: 64px;
          background: #f7f8fb;
          border-radius: 32px;
          font-size: 28px;
        }
      }
    }
  }

  // 顶部步骤条
  &-steps {
    padding: 8px 32px;
    $step: '.region-steps-item';
    #{$step} {
      position: relative;
      height: 60px;
      padding-left: 38px;
      &-body {
        display: flex;
        align-items: center;
        height: 100%;
      }
      &-circle {
        width: 14px;
        height: 14px;
        background: #cccccc;
        border-radius: 50%;
        position: absolute;
        left: 2px;
        top: 50%;
        transform: translateY(-50%);
      }
      &-line {
        top: 50%;
        width: 1px;
        height: 100%;
        background: #cccccc;
        position: absolute;
        left: 8px;
      }
      &.active {
        #{$step}-circle {
          left: 0;
          width: 18px;
          height: 18px;
          z-index: 1;
          background: #08c393;
        }
        #{$step}-body {
          color: #9ea2a5;
        }
      }
      &:last-of-type {
        #{$step}-line {
          display: none;
        }
      }
    }
  }
  .change-btn {
    width: 80px;
    height: 32px;
    color: #9ea2a5;
    border-color: #cccccc;
    line-height: 1;
    margin-left: 12px;
    font-size: 24px;
  }

  // 内容区块
  &-section {
    width: 100%;
    overflow: hidden;
    &-title {
      height: 80px;
      display: flex;
      align-items: center;
      padding: 0 32px;
      font-size: 24px;
      color: #999999;
    }
    &-body {
      padding: 0 32px;
    }
  }

  .region-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 156px;
    height: 64px;
    background: #e6f9f4;
    border-radius: 8px;
    font-size: 28px;
    color: #08c393;
    padding: 0 0.28rem;
    &.auto {
      min-width: 156px;
      width: auto;
    }
  }

  // 热门城市
  &-hotcity {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-bottom: -20px;
    .region-tag {
      flex: 0 0 auto;
      margin-right: 20px;
      margin-bottom: 20px;
      background: #f7f8fb;
      color: #333;
      &:active {
        color: #0cc393;
        font-weight: 700;
        background: #e6f9f4;
      }
    }
  }

  // 地区列表
  &-list {
    &-item {
      height: 96px;
      padding: 0 32;
      display: flex;
      align-items: center;
      position: relative;
      &-prefix {
        width: 48px;
        color: #999999;
        font-size: 24px;
      }
      &-body {
      }
      &::after {
        position: absolute;
        content: ' ';
        left: 32px;
        bottom: 0;
        height: 1px;
        background: #efefef;
        width: 100%;
        transform: scaleY(0.5);
      }
    }
  }
}
</style>
