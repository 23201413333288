<!------------------------------------------- template ------------------------------------------->
<template>
  <van-popup get-container="body" position="bottom" v-model="value" @closed="actionClosed" class="region">
    <div class="region-header">
      <span>选择城市</span>
      <div class="region-header-close" @click="value = false">
        <sz-icon class="ft16" name="close"></sz-icon>
      </div>
    </div>
    <div class="region-body" ref="regionBody">
      <!-- 进度条 -->
      <section class="region-steps" v-if="aSelectedCityList.length > 0">
        <div class="region-steps-item" :key="index" v-for="(item, index) in aSelectedCityList">
          <div class="region-steps-item-circle"></div>
          <div class="region-steps-item-line"></div>
          <div class="region-steps-item-body">
            <span>{{ item.regionChnName }}</span>
            <van-button v-if="index === 0" class="change-btn" round hairline plain size="mini" type="info" @click="initData"
              >更换</van-button
            >
          </div>
        </div>
        <div class="region-steps-item active">
          <div class="region-steps-item-circle"></div>
          <div class="region-steps-item-line"></div>
          <div class="region-steps-item-body">请选择城市</div>
        </div>
      </section>
      <!-- 当前选择地区 -->
      <section class="region-section" v-if="locationData && aSelectedCityList.length === 0">
        <div class="region-section-title">当前所在地区</div>
        <div class="region-section-body flex-box">
          <div class="region-tag auto" @click="actionChooseHotCity(locationData)">
            <sz-icon name="dingwei" class="ft16 mr5"></sz-icon>
            <span>{{ locationData.regionChnName }}</span>
          </div>
        </div>
      </section>

      <!-- 热门城市 -->
      <section class="region-section" v-if="hotCityList.length > 0 && aSelectedCityList.length === 0">
        <div class="region-section-title">热门城市</div>
        <div class="region-section-body region-hotcity">
          <div @click="actionChooseHotCity(item)" class="region-tag" v-for="(item, index) in hotCityList" :key="index">
            {{ item.regionChnName }}
          </div>
        </div>
      </section>

      <!-- 选择省份地区 -->
      <section class="region-section">
        <div class="region-section-title">选择省份/地区</div>
        <div class="region-section-body">
          <div class="region-list" v-if="isShowSelectAllBtn">
            <div class="region-list-item" @click="actionSelectAll">
              <div class="region-list-item-prefix"></div>
              <div class="region-list-item-body">全部</div>
            </div>
          </div>
          <div class="region-list" :key="item.title" v-for="item in aCityList">
            <div class="region-list-item" @click="actionConcatCity(item.type, city)" :key="index" v-for="(city, index) in item.list">
              <div class="region-list-item-prefix">
                <span v-if="index === 0">{{ city.regionCityCapAlp || city.regionCapAlp }}</span>
              </div>
              <div class="region-list-item-body">{{ city.regionChnName }}</div>
            </div>
          </div>
        </div>
      </section>

      <!-- 底部按钮 -->
      <div class="region-body-footer" v-if="aSelectedCityList.length > 0">
        <div class="region-body-footer-container">
          <!-- <div
            v-if="aSelectedCityList.length === 0"
            class="region-body-footer-container-btn"
            @click="actionClosed"
          >
            关闭
          </div>
          <div v-else class="region-body-footer-container-btn" @click="actionGoBack">返回</div> -->
          <div class="region-body-footer-container-btn" @click="actionGoBack">返回</div>
        </div>
      </div>
    </div>
  </van-popup>
  <!-- <van-action-sheet get-container="body" v-model="value" @closed="actionClosed" title="选择城市" class="region" :closeable="false">
    
  </van-action-sheet> -->
</template>

<!-------------------------------------------- script -------------------------------------------->
<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { getPosition } from '@/module/tools'
interface RegionMap {
  l1_CITY_D: any
  l2_CITY_D: any
  l3_CITY_D: any
}
interface HotCity {
  regionChnName: string
  // 其他属性...
}

// interface RegionData {
//   municipalityRegion: boolean // 是否为直辖市
//   regionChnName: string // 地区名称
//   regionCityCapAlp: string // 地区首字母
//   regionCode: string // 地区编号
// }
// https://cn.vuejs.org/v2/guide/typescript.html
// https://github.com/vuejs/vue-class-component
@Component({
  model: {
    prop: 'value',
    event: 'change'
  },
  components: {
    // About: () => import("../About.vue"),
  }
})
export default class Home extends Vue {
  @Prop() private value!: boolean
  @Prop({ default: false }) private useOldApi!: boolean
  @Prop({ default: false }) private reserveQuery!: boolean // 是否为预约时查询， 预约时字段为 true 后端有特殊逻辑
  @Prop() private goodsCode!: string // 商品编号，有商品编号时，查询可约地区需要调用新接口
  @Prop() private orderCode!: string // 订单编号 - 通过订单获取当前订单支持预约的地区
  @Prop() private addGoodsCodeListStr!: string // 加项包编号
  @Prop() private gender!: string // 用户性别

  locationData: any = null
  // 地区数据
  oRegionData: RegionMap = {
    l1_CITY_D: null,
    l2_CITY_D: null,
    l3_CITY_D: null
  }

  isShowSelectAllBtn = false // 是否展示选择全部按钮
  isRefreshDone = true // 是否刷新完成
  isConcatDone = false // 选择城市是否结束, 与l3级地区存在关联关系热门城市列表
  nCurrentNo = 0 // 第几个
  hotCityList: HotCity[] = [] // isRefreshDone: true, // 是否刷新完成
  aCityList: Array<any> = [] // 当前展示的城市列表
  aSelectedCityList: Array<any> = [] // 当前选中的城市列表
  sCurrentCityCode = '' // 当前选中的城市Code
  sCurrentL2RegionCode = '' // 当前选中的二级地区Code

  /* 一.生命周期函数 */
  created(): void {
    this.ajaxQueryHotCity()

    if (this.goodsCode) {
      // 有商品编号，需要查询支持该商品的机构
      this.ajaxQueryReserveRegionsNew()
    } else {
      this.ajaxQueryReserveRegions()
    }

    // 获取用户当前位置
    getPosition().then(res => {
      if (res.latitude) {
        this.ajaxGetCityByLocation(res.latitude, res.longitude)
      }
    })
  }

  /* 二.监控函数 */
  @Watch('value')
  onMsgChanged(val: string): void {
    if (!val) {
      this.initData()
    }
  }

  // 监听性别变化 性别变化后需要重新查询可选地区
  @Watch('gender')
  onGenderChanged(): void {
    if (this.goodsCode) {
      // 有商品编号，需要查询支持该商品的机构
      this.ajaxQueryReserveRegionsNew()
    }
  }

  @Watch('aSelectedCityList', { deep: true })
  onSelectedCityListChanged(val: any): void {
    this.isShowSelectAllBtn = val && val.length > 0 && val[0].municipalityRegion
  }

  /* 三.内部功能函数 */
  /* ----------------------事件调用函数------------------------ */
  // 弹窗关闭
  actionClosed() {
    this.$emit('change', false)
  }
  actionGoBack() {
    this.aSelectedCityList = this.aSelectedCityList.slice(0, -1)
    // 返回后展示省份
    this.aCityList = this.formatData('l1', this.oRegionData.l1_CITY_D)
  }
  // 追加或切换城市/地区到城市列表
  actionConcatCity(type, city) {
    this.concatCity(type, city)
  }

  // 直辖市选择全部
  actionSelectAll() {
    this.$emit('on-complete', this.aSelectedCityList)
  }

  // 选择热门城市
  actionChooseHotCity(city) {
    if (!this.oRegionData.l1_CITY_D) {
      this.ajaxQueryReserveRegions()
      return this.$toast('尚未初始化完成，请稍候')
    }

    this.initData()

    // municipalityRegion true 直辖市 false 普通省份
    const type = city.municipalityRegion ? 'l1' : 'l2'
    this.concatCity(type, city)
  }

  /* ----------------------内部功能函数------------------------ */
  // 内部功能函数注释
  // doSomething(): void {}
  // 初始化数据
  initData() {
    this.aCityList = this.formatData('l1', this.oRegionData.l1_CITY_D)
    this.aSelectedCityList = []
  }
  // 格式化省级数据
  formatData(type, oL1Data) {
    if (!oL1Data) {
      this.$toast('该地区暂未开通')
    }
    return Object.keys(oL1Data).map(tag => {
      return {
        type,
        title: tag,
        list: oL1Data[tag]
      }
    })
  }

  // 步骤条中追加城市
  concatCity(type, city) {
    const oCity = {
      type,
      ...city
    }
    switch (type) {
      case 'l1': // 省
        // municipalityRegion true 直辖市 false 普通省份
        if (city.municipalityRegion) {
          this.aCityList = this.formatData('l2', this.oRegionData.l2_CITY_D[`${city.regionCode}`])

          // if (this.goodsCode) {
          //   // 有商品编号，需要查询支持该商品的机构，但由于数据结构不一致，所以需要传不同的参数
          //   this.aCityList = this.formatData('l2', this.oRegionData.l2_CITY_D[`${city.regionCode}`])
          // } else {
          //   this.aCityList = this.formatData('l3', this.oRegionData.l3_CITY_D[`${city.regionCode}01`])
          // }
          console.log(this.aCityList)
          this.aCityList.push({})
        } else {
          this.aCityList = this.formatData('l2', this.oRegionData.l2_CITY_D[city.regionCode])
          console.log(this.aCityList)
        }
        break
      case 'l2': // 市
      case 'l3': // 区
        // 展示机构 - 只有在选择三级区域的时候才可以展示机构
        this.$emit('on-complete', [...this.aSelectedCityList, oCity])
        return
    }

    this.isRefreshDone = false
    this.aSelectedCityList.push(oCity)
    this.$nextTick(() => {
      this.isRefreshDone = true
      const len = this.aSelectedCityList.length
      this.nCurrentNo = this.isConcatDone ? len : len - 1
    })
  }

  /* ----------------------服务请求函数------------------------ */
  // 服务请求函数注释

  ajaxQueryReserveRegionsNew() {
    return this.$api
      .slget('cgi_queryReserveRegionsNew', {
        reserveQuery: this.reserveQuery,
        goodsCode: this.goodsCode,
        orderCode: this.orderCode,
        addGoodsCodeList: this.addGoodsCodeListStr,
        gender: this.gender
      })
      .then(res => {
        if (res.SZ_HEAD.RESP_CODE === 'S0000') {
          this.oRegionData = {
            l1_CITY_D: res.SZ_BODY.L1_CITY_D,
            l2_CITY_D: res.SZ_BODY.L2_CITY_D,
            l3_CITY_D: res.SZ_BODY.L3_CITY_D
          }
          this.initData()
        } else {
          this.$toast(res.SZ_HEAD.RESP_MSG)
        }
      })
  }

  // 查询可约地区
  ajaxQueryReserveRegions() {
    return this.$api.slget('examine_queryRegionRegionsInfo').then(res => {
      // return this.$api.slget('queryReserveRegion').then(res => {
      if (res.SZ_HEAD.RESP_CODE === 'S0000') {
        // this.oRegionData = res.SZ_BODY
        this.oRegionData = {
          l1_CITY_D: res.SZ_BODY.l1RegionCapAlpMap,
          l2_CITY_D: res.SZ_BODY.l1AndL2RegionMap,
          l3_CITY_D: {}
        }
        this.initData()
      } else {
        this.$toast(res.SZ_HEAD.RESP_MSG)
      }
    })
  }

  // 获取热门城市
  ajaxQueryHotCity() {
    this.$api
      .slget(
        'cgi_baseHotCityShow',
        {},
        {
          cache: true
        }
      )
      .then(res => {
        if (res.SZ_HEAD.RESP_CODE === 'S0000') {
          this.hotCityList = res.SZ_BODY.HOT_CITY_LIST || []
          if (this.$store.state.fromCompany === 'bcm') {
            this.hotCityList = this.hotCityList.filter(item => item.regionChnName !== '西安')
          }

          console.log('this.hotCityList111', this.hotCityList)
        } else {
          this.$toast(res.SZ_HEAD.RESP_MSG)
        }
      })
  }

  // 通过定位获取当前城市
  ajaxGetCityByLocation(latitude, longitude) {
    return this.$api
      .slget(
        'getCityByLocation',
        {
          latitude,
          longitude
        },
        {
          cache: false
        }
      )
      .then(res => {
        if (res.SZ_HEAD.RESP_CODE === 'S0000') {
          const { LOCATION_CITY } = res.SZ_BODY
          if (LOCATION_CITY) {
            const { isMunicipality, regionChnName, regionCapAlp, regionCode } = LOCATION_CITY
            this.locationData = {
              municipalityRegion: isMunicipality === 1,
              regionChnName,
              regionCityCapAlp: regionCapAlp,
              regionCode
            }
          }
        } else {
          // this.$toast(res.SZ_HEAD.RESP_MSG)
        }
      })
  }
}
</script>

<!-------------------------------------------- style -------------------------------------------->
<style scoped lang="scss">
.region {
  height: 85vh;
  border-radius: 32px 32px 0 0;
  display: flex;
  flex-direction: column;
  &-header {
    width: 100%;
    position: relative;
    font-size: 36px;
    line-height: 100px;
    text-align: center;
    &-close {
      color: #c8c9cc;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
    }
  }
  .flex-box {
    display: flex;
  }
  // 弹窗大小
  &-body {
    position: relative;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    // 底部关闭按钮
    &-footer {
      width: 100%;
      height: 96px;
      &-container {
        width: 100%;
        height: 96px;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: center;
        z-index: 1;
        bottom: 0;
        left: 0;
        background: #fff;
        &-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 192px;
          height: 64px;
          background: #f7f8fb;
          border-radius: 32px;
          font-size: 28px;
        }
      }
    }
  }

  // 顶部步骤条
  &-steps {
    padding: 8px 32px;
    $step: '.region-steps-item';
    #{$step} {
      position: relative;
      height: 60px;
      padding-left: 38px;
      &-body {
        display: flex;
        align-items: center;
        height: 100%;
      }
      &-circle {
        width: 14px;
        height: 14px;
        background: #cccccc;
        border-radius: 50%;
        position: absolute;
        left: 2px;
        top: 50%;
        transform: translateY(-50%);
      }
      &-line {
        top: 50%;
        width: 1px;
        height: 100%;
        background: #cccccc;
        position: absolute;
        left: 8px;
      }
      &.active {
        #{$step}-circle {
          left: 0;
          width: 18px;
          height: 18px;
          z-index: 1;
          background: #08c393;
        }
        #{$step}-body {
          color: #9ea2a5;
        }
      }
      &:last-of-type {
        #{$step}-line {
          display: none;
        }
      }
    }
  }
  .change-btn {
    width: 80px;
    height: 32px;
    color: #9ea2a5;
    border-color: #cccccc;
    line-height: 1;
    margin-left: 12px;
    font-size: 24px;
  }

  // 内容区块
  &-section {
    width: 100%;
    overflow: hidden;
    &-title {
      height: 80px;
      display: flex;
      align-items: center;
      padding: 0 32px;
      font-size: 24px;
      color: #999999;
    }
    &-body {
      padding: 0 32px;
    }
  }

  .region-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 156px;
    height: 64px;
    background: #e6f9f4;
    border-radius: 8px;
    font-size: 28px;
    color: #08c393;
    padding: 0 0.28rem;
    &.auto {
      min-width: 156px;
      width: auto;
    }
  }

  // 热门城市
  &-hotcity {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-bottom: -20px;
    .region-tag {
      flex: 0 0 auto;
      margin-right: 20px;
      margin-bottom: 20px;
      background: #f7f8fb;
      color: #333;
      &:active {
        color: #0cc393;
        font-weight: 700;
        background: #e6f9f4;
      }
    }
  }

  // 地区列表
  &-list {
    &-item {
      height: 96px;
      padding: 0 32;
      display: flex;
      align-items: center;
      position: relative;
      &-prefix {
        width: 48px;
        color: #999999;
        font-size: 24px;
      }
      &-body {
      }
      &::after {
        position: absolute;
        content: ' ';
        left: 32px;
        bottom: 0;
        height: 1px;
        background: #efefef;
        width: 100%;
        transform: scaleY(0.5);
      }
    }
  }
}
</style>
