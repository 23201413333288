/* ------------------------------------------- template
------------------------------------------- */
<template>
  <div class="sz-verify-code">
    <!-- 图形验证码输入框 -->
    <transition name="fade">
      <div
        v-show="isShow"
        class="sz-mask sz-animated"
        @click.prevent="close"
      ></div>
    </transition>
    <transition name="zoom">
      <div v-show="isShow" class="content sz-animated">
        <!-- 顶部关闭按钮 -->
        <div class="title">操作频繁</div>
        <div class="sub-title">请输入图形验证码后继续</div>
        <!-- 用户输入框 -->
        <div class="code-content1">
          <input
            v-model.trim="sGraphCode"
            class="code-content1-code"
            placeholder="输入图形验证码"
            maxlength="4"
            @blur="scrollBy"
          />
          <img
            :src="sGraphCodeImg"
            class="code-content1-img"
            height="100%"
            alt=""
            @click="ajaxGetGraphic"
          />
        </div>
        <div class="tip">
          <span>点击图片可重新获取</span>
        </div>
      </div>
    </transition>
  </div>
</template>

/* -------------------------------------------- script
-------------------------------------------- */
<script>
import xss from "xss"; // xss过滤
export default {
  name: "szVerifyCode",
  components: {},

  props: {
    mobile: {
      type: String,
      default: "",
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isSending: false, // 是否发送中
      sGraphCodeImg: "", // base64转码后的图片
      sGraphCode: "", // 图形验证码
    };
  },

  /* 一.生命周期函数 */
  created() {},

  /* 二.监控函数 */
  watch: {
    sGraphCode(newVal) {
      if (newVal.length === 4) {
        this.changeGraphCode();
      }
    },
  },

  computed: {},

  /* 三.内部功能函数 */
  methods: {
    /* ----------------------事件调用函数------------------------ */
    // 刷新图形验证码
    actionRefreshGraphCode() {
      this.checkMobile().then(() => {
        this.ajaxGetGraphic();
      });
    },
    /* ----------------------内部功能函数------------------------ */
    close() {
      this.$emit("on-close", this.sGraphCode);
    },
    // 图形验证码改变
    changeGraphCode() {
      this.$emit("on-change", this.sGraphCode);
    },
    initGraphCode(img) {
      this.sGraphCodeImg = img;
      this.sGraphCode = "";
      this.isSending = false;
    },
    // 校验手机号
    validateMobile(mobile) {
      return !!/^1[3456789]\d{9}$/.test(mobile);
    },
    checkMobile() {
      return new Promise((resolve) => {
        if (this.mobile === "") {
          return this.$toast("手机号码不能为空");
        } else if (!this.validateMobile(this.mobile)) {
          return this.$toast("手机号码格式错误，请重输");
        }
        resolve();
      });
    },
    // 处理微信兼容问题，IOS微信点开输入框后页面会被顶上去
    scrollBy() {
      window.scrollBy(0, 0);
    },
    /* ----------------------服务请求函数------------------------ */
    // 服务请求函数注释
    // 获取图形验证码
    ajaxGetGraphic() {
      this.isSending = true;
      this.$api
        .get("/is/user/get_graphic", {
          mobile: xss(this.mobile),
        })
        .then((res) => {
          if (res.SZ_HEAD.RESP_CODE === "S0000") {
            this.sGraphCodeImg = res.SZ_BODY.image;
          } else {
            this.$toast(res.SZ_HEAD.RESP_MSG);
          }
        })
        .finally(() => {
          this.isSending = false;
        });
    },
  },
};
</script>

/* -------------------------------------------- style
-------------------------------------------- */
<style scoped lang="scss">
.sz-animated {
  transform: translateZ(0); // 强制开启IOS GPU加速
  // animation-duration: 2s;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: both;
}
.sz-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.sz-verify-code {
  .content {
    position: fixed;
    top: 20%;
    left: 10%;
    z-index: 1000;
    display: flex;
    flex: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    height: rem(480);
    padding: rem(60) 0 rem(40);
    background: #fff;
    border-radius: rem(20);
    // 关闭按钮
    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.35rem;
    }
    .title {
      color: #333;
      font-size: rem(44);
    }
    .sub-title {
      position: relative;
      top: rem(-30);
      color: #999;
      font-size: rem(32);
    }
    .code-content1 {
      position: relative;
      top: rem(-30);
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: rem(80);
      padding: 0 rem(70);
      &-code {
        width: rem(190);
        padding: 0 rem(20);
        border: 1px solid #eee;
        border-radius: rem(4);
      }
    }
    .tip {
      color: #b9b9b9;
      font-size: rem(32);
      &.pink {
        color: #ff6e6e;
      }
    }
  }
}
</style>
