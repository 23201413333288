import Vue from 'vue'
import szPage from './szPage/index.vue'
import szIcon from './szIcon/index.vue'
import szHeader from './szHeader/index.vue'
import szFooter from './szFooter/index.vue'
import szCityRegion from './szCityRegion/index.vue'
import szRegion from './szRegion/index.vue'
import szMDrag from './szMDrag/index.vue'
import szInstit from './szInstit/index.vue'
import szFormItem from './szFormItem/index.vue'
import szTag from './szTag.vue'
import lhFooter from './lhFooter/index.vue'
import szGraphCode from "./szGraphCode/index.vue";

Vue.component('szPage', szPage)
Vue.component('szIcon', szIcon)
Vue.component('szHeader', szHeader)
Vue.component('szFooter', szFooter)
Vue.component('szCityRegion', szCityRegion)
Vue.component('szRegion', szRegion)
Vue.component('szInstit', szInstit)
Vue.component('szMDrag', szMDrag)
Vue.component('szFormItem', szFormItem)
Vue.component('szTag', szTag)
Vue.component('lhFooter', lhFooter)
Vue.component('szGraphCode', szGraphCode)
