import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/scss/main.scss'
import '@/scss/tailwindcss.scss'
import '@/module/libs/rem.js'
import '@/assets/fonts/font.js'
import '@/exposes/biz'

// 插件相关
import GlobalVarPlugin from '@/module/plugins/globalVar'
import miniProgramPlugin from '@/module/plugins/miniProgramPlugin'
import GlobalCssVarPlugin from '@/module/plugins/globalCssVar'
import ApiPlugin from '@/module/plugins/api'
// import SentryPlugin from '@/module/plugins/sentry'
import SensorPlugin from '@/module/plugins/sensor'
import ABTestPlugin from '@/module/plugins/abTest'
import IndexedDBPlugin from '@/module/plugins/db'

// 添加插件
// Vue.use(GlobalVarPlugin).use(ApiPlugin).use(SentryPlugin).use(SensorPlugin).use(ABTestPlugin).use(IndexedDBPlugin)
Vue.use(GlobalVarPlugin)
  .use(GlobalCssVarPlugin)
  .use(ApiPlugin)
  .use(SensorPlugin)
  .use(ABTestPlugin)
  .use(IndexedDBPlugin)
  .use(miniProgramPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
declare module 'vue/types/vue' {
  interface Vue {
    $wxReady: any
    $Message: any
    $api: any
    $db: any
    // $sa: any // 神策埋点
    $szGrayTest: any // 灰度测试
    $toast: any // Vant 自带
    $dialog: any // Vant Dialog
    $isWechat: any // 是不是微信环境
    $getCookie: any // 是不是微信环境
    $xss: any // xss
    $toLogin: any // xss
    $miniRedirectTo: any // 跳转微信小程序
  }
}
